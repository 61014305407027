



























































import { Component, Prop, PropSync } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({ name: 'TypeDialog' })
export default class TypeDialog extends VueBase {
  @Prop() pId: any
  @Prop() vId: any
  @PropSync("show", { required: true }) showProp: any

  private tableData: any = {
    data: [],
    total: 0,
    page: 1,
    page_size: 10,
  }
  
  private levelMap = {
    1: {name: '高危', bg: 'var(--color-risk-high-bg)', color: 'var(--color-risk-high)'},
    2: {name: '中危', bg: 'var(--color-risk-med-bg)', color: 'var(--color-risk-med)'},
    3: {name: '低危', bg: 'var(--color-risk-low-bg)', color: 'var(--color-risk-low)'},
    5: {name: '提示', bg: 'var(--color-risk-tip-bg)', color: 'var(--color-risk-tip)'},
  }

	get dialogVisible() {
		if (this.showProp) {
			this.init()
		}
		return this.showProp
	}
	set dialogVisible(val: any) {
		this.showProp = val
	}

  async init() {
    const { data, status, page } = await this.services.project.getTypeSummary(this.pId, {
      page: this.tableData.page,
      page_size: this.tableData.page_size,
      version_id: this.vId
    })
    if (status !== 201) return
    this.tableData.data = data
    this.tableData.total = page.alltotal
  }

  currentChange(val: any) {
    this.tableData.page = val
    this.init()
  }

  handleClose() {
    this.dialogVisible = false
  }
}
